jQuery(document).ready(function ($) {
  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function () {
    $('.nav-toggle').toggleClass('open');
    $('.nav-primary').toggleClass('open');
  });

  $('.nav-primary .nav-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.nav-toggle').toggleClass('open');
      $('.nav-primary').toggleClass('open');
    }
  });

  $('.nav-primary li').on('click', function () {
    $(this).toggleClass('open-submenu');
  });

  // primary nav
  $('.nav-primary').accessibleNav({
    desktop: 50000,
    spans: 'hide',
    level2position: 'vertical-bottom'
  });
});